<template>
  <Html class="app">
    <Body>
      <v-app class="position-relative">
        <slot />
      </v-app>
    </Body>
    <slot name="after-main" />
    <!-- @vue-ignore -->
    <TheNotifications
      position="top right"
      width="500"
      pause-on-hover
    />
  </Html>
</template>

<script setup lang="ts">
import { useLocalStorage } from "@vueuse/core";
import { useTheme } from "vuetify";
const { t } = useI18n();
const appConfig = useAppConfig();

const i18nHead = useLocaleHead({});

useHead({
  titleTemplate: (title) => {
    const part = title ? t(title as string) : "";
    return part ? `${appConfig.baseTitle} - ${part}` : appConfig.baseTitle as string;
  },
  htmlAttrs: {
    ...i18nHead.value.htmlAttrs,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])]
});

const theme = useTheme();
const preferredTheme = useLocalStorage("preferredTheme", "light");

onMounted(() => {
  theme.global.name.value = preferredTheme.value;
});
</script>
